@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "styles/variables";
@import "~noty/src/noty.scss";
@import "~noty/src/themes/mint.scss";

html {
  height: 100%;
}

body {
  min-height: 100%;
}

@media (min-width: 768px) {
  .container {
    max-width: 900px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

a {
  color: #0065b8;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.padding-none {
  padding: 0;
}
.intl-tel-input {
  position: relative;
  display: block;

  .country-list {
    .country {
      padding: 5px 10px;
      white-space: normal;
    }
  }
}

.form-control::-webkit-input-placeholder {
  color: #c7c6c6;
  font-weight: normal;
} /* WebKit, Blink, Edge */
.form-control:-moz-placeholder {
  color: #c7c6c6;
  font-weight: normal;
} /* Mozilla Firefox 4 to 18 */
.form-control::-moz-placeholder {
  color: #c7c6c6;
  font-weight: normal;
} /* Mozilla Firefox 19+ */
.form-control:-ms-input-placeholder {
  color: #c7c6c6;
  font-weight: normal;
} /* Internet Explorer 10-11 */
.form-control::-ms-input-placeholder {
  color: #c7c6c6;
  font-weight: normal;
} /* Microsoft Edge */

form {
  .has-error {
    border: 2px solid #ff0000 !important;
    &:focus {
      border: 2px solid #ff0000 !important;
    }
  }
  .dash.has-error {
    -webkit-box-shadow: none !important;
    box-shadow: none !important ;
    border-right: none !important;
    border-left: none !important;
  }
  .active-datepicker.has-error {
    border-left: 1px solid #a94442 !important;
    &:focus {
      border-color: #843534;
      -webkit-box-shadow: 0 0 0.2rem 0.2rem rgba(0, 0, 0, 0.075),
        0 0 6px #ce8483 !important;
      box-shadow: 0 0 0.2rem 0.2rem rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
    }
  }
  #roundTripDepart.has-error {
    background-color: #fff3f3;
    border-right: 0px !important;
    -webkit-box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.075) !important;
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.075) !important;
  }
  #roundTripReturn.has-error {
    background-color: #fff3f3;
    border-left: 0px !important;
    -webkit-box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.075) !important;
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.075) !important;
  }
  #roundTripDepart.active-datepicker.has-error {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.075), 0 0 6px #ce8483 !important;
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.075), 0 0 6px #ce8483 !important;
  }
  #roundTripReturn.active-datepicker.has-error {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.075), 0 0 6px #ce8483 !important;
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.075), 0 0 6px #ce8483 !important;
  }
}

/* modal animation */
.modal-content {
  animation-name: modal-show;
  animation-duration: 0.4s;
}

.modal-backdrop {
  //opacity: 0!important;
}

@keyframes modal-show {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes backdrop-show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}
.hiw-backdrop {
  z-index: 10060 !important;
  animation-name: backdrop-show;
  animation-duration: 0.4s;
  //opacity: 0!important;
}

.how-it-works-dialog {
  .mat-dialog-container {
    max-width: none;
    padding: 0;
  }
}

.call-me-dialog {
  //.mat-dialog-container {
  //  max-width: none;
  //  padding: 0;
  //  border-radius: .3rem;
  //}
}

.cdk-overlay-dark-backdrop {
  background: #0a0a0a;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.9;
}
//.write-review-dialog {
//  margin-top: 2%;
//}
.mat-dialog-container {
  padding: 0px !important;
  border-radius: 0.3rem !important;
}

.cdk-overlay-container {
  z-index: 1050;
}

ngb-pagination {
  .pagination {
    .page-item.active {
      .page-link {
        color: #fff;
        background-color: $main_color;
        border-color: $main_color;
      }
    }
    .page-item {
      .page-link {
        color: $main_color;
      }
    }
  }
}

#page-flights-list {
  .page-text {
    p {
      line-height: 30px;
    }
    img {
      border-radius: 3px;
    }
    @media (max-width: 991px) {
      h2 {
        font-size: 26px;
      }
      p {
        font-size: 14px;
        line-height: 26px;
      }
    }
    @media (max-width: 576px) {
      h2 {
        font-size: 20px;
      }
    }
  }
  .card {
    border: 0;
    border-radius: 5px;
    padding-bottom: 30px;
    .card-block .btn {
      background: #0065b8;
      color: #fff;
      border: 0;
      border-radius: 2px;
      width: 120px;
      padding: 12px;
      margin-bottom: 20px;
      margin-top: 20px;
      &:hover {
        color: #fff;
      }
    }
    .flights-list-img {
      position: relative;
      a {
        .flights-hover-bth {
          display: none;
        }
        &:hover .flights-hover-bth {
          background-color: rgba(0, 101, 184, 0.8);
          width: 100%;
          height: 100%;
          position: absolute;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -ms-flex-align: center;
          -webkit-align-items: center;
          -webkit-box-align: center;
          align-items: center;
          .btn {
            background: #fff;
            color: $main_color;
            border: 0;
            border-radius: 2px;
            width: 120px;
            padding: 12px;
            cursor: pointer;
            margin: 0 auto;
          }
        }
      }
      h3 {
        position: absolute;
        bottom: 10px;
        padding-left: 15px;
        color: #fff;
        font-size: 36px;
        font-weight: bold;
        text-align: left;
        text-shadow: 1px 1px 2px black, 0 0 1em black;
        small {
          font-size: 20px;
        }
      }
      span {
        position: absolute;
        bottom: 10px;
        right: 20px;
        color: #fff;
        font-size: 36px;
        font-weight: bold;
        text-align: right;
        margin-bottom: 0.5rem;
        line-height: 1.2;
        text-shadow: 1px 1px 2px black, 0 0 1em black;
        small {
          font-size: 20px;
        }
      }
      @media (max-width: 991px) {
        h3 {
          font-size: 22px;
        }
        span {
          font-size: 22px;
        }
      }
    }
    .flights-list-block {
      border: 1px solid rgba(0, 0, 0, 0.125);
      border-top: 0;
      border-radius: 0 0 5px 5px;
      .flights-list-info {
        margin: 0 15px;
        padding: 20px 0;
        border-bottom: 1px solid gainsboro;
        .list-th-one {
          width: 50%;
          text-align: left;
          font-size: 16px;
          color: #adadad;
        }
        .list-th-two {
          width: 50%;
          text-align: right;
          font-size: 16px;
          color: #adadad;
        }
      }
      a {
        text-decoration: none;
        display: inline-block;
        width: 100%;
        color: $main_color;
        &:hover {
          color: #007fe8;
          text-decoration: none;
        }
        &:last-child .row {
          border-bottom: 0;
        }
        .row {
          margin: 0 15px;
          padding: 20px 0;
          border-bottom: 1px solid gainsboro;
          &:hover {
            border-bottom: 1px solid $main_color;
          }
          h2 {
            width: 50%;
            font-size: 20px;
            font-weight: 400;
            text-align: left;
            margin-bottom: 0;
            i {
              background: url("./assets/img/right-arrow.svg");
              position: absolute;
              height: 10px;
              width: 10px;
              margin-left: 10px;
              margin-top: 9px;
              background-size: 10px 10px;
            }
          }
          .list-value {
            width: 50%;
            font-size: 23px;
            font-weight: bold;
            line-height: 22px;
            text-align: right;
          }
          @media (max-width: 991px) {
            h2 {
              font-size: 18px;
            }
            h2 {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}

.block {
  display: block;
}

.card {
  display: block !important;
}

input::-ms-clear {
  display: none;
}

.margin-bottom {
  margin-bottom: 1em;
}

ngb-datepicker {
  left: calc(100% - 472px) !important;
  margin-top: 5px !important;
}

@media (max-width: 767.98px) {
  .depart-rt ngb-datepicker {
    left: calc(100% - 241px) !important;
  }
  .return-rt ngb-datepicker {
    left: calc(100% - 234px) !important;
  }
  #one-way-form ngb-datepicker,
  #multi-city-form ngb-datepicker {
    left: 0 !important;
  }
}

@media (max-width: 576px) {
  .depart-rt ngb-datepicker {
    left: 0 !important;
  }
  .return-rt ngb-datepicker {
    left: calc(100% - 233px) !important;
  }
  #noty_layout__bottomRight,
  #noty_layout__topCenter {
    .noty_bar {
      display: block !important;
      .noty_buttons {
        display: block !important;
      }
    }
  }
}

#noty_layout__topCenter {
  top: 0;
  margin-top: 10px;
}

#noty_layout__bottomRight,
#noty_layout__topCenter {
  width: 600px;
  .noty_bar {
    display: flex;
    justify-content: center;
    align-items: center;
    .noty_buttons {
      border-top: none !important;
      display: inline-flex;
      button {
        background-color: transparent;
        font-weight: 500;
        font-size: 17px;
        &:focus {
          box-shadow: none;
        }
      }
      .apply-update {
        color: #0065b8;
        &:hover {
          background-color: rgba(63, 81, 181, 0.08);
        }
      }
      .dismiss-update {
        color: #f50057;
        &:hover {
          background-color: rgba(245, 0, 87, 0.08);
        }
      }
    }
  }
  .noty_type__warning {
    color: #332a09;
  }
}

.modal {
  top: 3.5em;
}

.cdk-overlay-container {
  z-index: 10055 !important;
}
.modal {
  z-index: 10060 !important;
}
app-searchbox-kayak {
  .intl-tel-input {
    .selected-flag {
      padding: 0 0 0 13px !important;
      .iti-flag {
        margin-top: 35px !important;
      }
      .iti-arrow {
        margin-top: 10px !important;
        right: 0px !important;
      }
    }
  }
}

app-trustpilot-box.kayak section#trustpilot-box {
  padding-top: 40px !important;
  padding-bottom: 0px !important;
}
app-trustpilot-box.kayak section#trustpilot-box #trust-box {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.datepicker-style {
  .dropdown-menu {
    display: block;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.05);
    border: 0;
    border-radius: 10px;
  }
  .btn-light {
    font-size: 12px;
    font-weight: bold;
  }
  .ngb-dp-weekday {
    color: #145bda !important;
    font-style: normal !important;
    font-weight: bold;
  }
  .ngb-dp-weekdays {
    border-bottom: 0 !important;
  }
  .ngb-dp-month-name {
    font-size: 14px !important;
    font-weight: bold;
    background-color: #ffffff !important;
  }
  .ngb-dp-header {
    border-radius: 0.5rem 0.5rem 0 0 !important;
    background-color: #ffffff !important;
  }
  .btn-link {
    color: #145bda !important;
  }
}

.mat-autocomplete-panel:not([class*="mat-elevation-z"]) {
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.before-close-dialog {
  .modal-dialog {
    max-width: 600px;
    .modal-content {
      background-color: #0065b8;
      border: 0;
      border-radius: 3px;
      text-align: center;
    }
  }
}

.intl-tel-input.separate-dial-code .selected-flag {
  background-color: transparent;
}

.cruise-options {
  &.mat-autocomplete-panel {
    max-height: 400px;
  }
  .mat-option {
    height: 40px;
  }
}

.mat-autocomplete-panel.cruise-dropdown-full-with {
  min-width: fit-content;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cdk-overlay-pane:has(.dropdown-left-border) {
  display: flex;
  justify-content: flex-end;

  .dropdown-left-border {
    min-width: fit-content;
  }
}

@media (max-width: 767px) {
  .before-close-dialog .modal-dialog .modal-content {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .before-close-dialog form .bottom-form a {
    font-size: 12px;
    margin-left: 20px;
  }
}

apple-pay-button {
  --apple-pay-button-width: 140px;
  --apple-pay-button-height: 50px;
  --apple-pay-button-border-radius: 4px;
  --apple-pay-button-padding: 0px 0px;
  --apple-pay-button-box-sizing: border-box;
}

.searchbox-down-sections-indents {
  padding-left: 10%;
  padding-right: 10%;
  margin-left: 0;
  margin-right: 0;
}
